@media print {
    .print-content {
        page-break-inside: avoid;
    }

    @page {
        margin: 50px;
    }

    @page {
        size: auto;
        margin: 0;
    }

    body::after {
        content: none !important;
    }
}

@media print {
    .print-content2 {
        page-break-before: always;
        
    }
    body {transform: scale(.99);}
   
 
}
   